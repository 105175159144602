@font-face {
  font-family: Kreon;
  src: url(Kreon-VariableFont_wght.ttf);
}

@font-face {
  font-family: Open-Sans;
  src: url(OpenSans-Regular.ttf);
}

* {
  box-sizing: border-box;
}

html {
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
  background-color: #fff;
}

h1 {
  font-family: Kreon;
  font-size: 17px;
  font-weight: 700;
  line-height: 19px;
  margin: 0;
  text-align: center;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h2 {
  color: #353738;
  font-family: Kreon;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  margin: 10px 0 24px;
  text-align: center;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

a {
  text-decoration: none;
}

.App {
  background-color: #f4f7f8;
  margin: 0 auto;
  padding: 78px 0 100px;
  min-height: 100vh;
  width: 100%;
}

.portfolio-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 30px;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 12px;
  width: 1110px;
}

.App-header {
  grid-column: 1/span 12;
}

.profile-info {
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(71, 84, 97, 0.2);
  margin-bottom: 40px;
  padding: 78px 25px 25px;
  position: relative;
  text-align: center;
  z-index: 3;
}

.fake-cut {
  content: "";
  width: 106px;
  height: 56px;
  background-color: #f4f7f8;
  box-shadow: inset 0 0 2px 0 rgba(71, 84, 97, 0.2);
  border-bottom-left-radius: 106px;
  border-bottom-right-radius: 106px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.fake-cut-line {
  content: "";
  width: 104px;
  height: 3px;
  background-color: #f4f7f8;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -1px;
}

.profile-picture {
  width: 106px;
  height: 106px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: -48px;
  left: 50%;
  transform: translateX(-50%);
  border: 10px solid transparent;
  z-index: 1;
  background-color: transparent;
}

.profile-picture img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.profile-links {
  margin-bottom: 20px;
}

.btn {
  border: 1px solid rgba(101, 101, 101, 0.2);
  border-radius: 20px;
  color: #656565;
  cursor: pointer;
  display: inline-block;
  font-family: Kreon;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 15px;
  padding: 6px 10px;
  text-align: center;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  text-transform: uppercase;
  transition-property: color, background-color;
  transition-duration: 0.2s;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 94px;
}

.btn:first-of-type {
  margin-right: 5px;
}

.btn:nth-of-type(2) {
  margin-left: 5px;
}

.btn a {
	color: #656565;
}

.btn:hover {
  background-color: #9a9fa5;
  color: #fff;
}

.btn:hover a {
  color: #fff;
}

.profile-about {
  color: #60676d;
  font-family: "Open-Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 18px;
  text-align: left;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.projects-container {
  grid-column: 1/span 12;
}

.projects-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 14px;
  grid-row-gap: 24px;
}

.project {
  max-width: 100%;
  width: 100%;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.project-name {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: #fff;
  text-align: center;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .2s ease-out;
  text-decoration: none;
}

.image-container {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 100%;
  text-align: center;
}

.projects-list .project img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .2s;
  object-fit: cover;
}

.project-title {
  font-family: Kreon;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 7px;
}

.project-subtitle {
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
}

img {
  vertical-align: middle;
  border-style: none;
}

a:hover .image-container img {
  filter: blur(10px);
  transform: scale(1.03);
}

@media (min-width: 375px) {
  .profile-info {
    margin-left: 9px;
    margin-right: 9px;
  }

  .projects-list {
    padding-left: 9px;
    padding-right: 9px;
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: 24px;
    line-height: 27px;
  }

  h2 {
    font-size: 14px;
    line-height: 21px;
  }

  .App-header {
    grid-column: 1/span 3;
  }

  .profile-info {
    margin-right: 0;
    margin-left: 0;
  }

  .projects-container {
    grid-column: 4/span 9;
  }

  .projects-list {
    grid-column: 4/span 9;
    grid-row-gap: 14px;
    grid-template-columns: 1fr 1fr;
    padding-left: 0;
    padding-right: 0;
  }

  .project-title {
    font-size: 26px;
    line-height: 29px;
    margin-bottom: 16px;
  }

  .project-subtitle {
    font-size: 16px;
    font-weight: normal;
    line-height: 19px;
  }
}

@media (min-width: 992px) {
  .profile-info {
    position: sticky;
    position: -webkit-sticky;
    top: 48px;
  }
}

@media (max-width: 1024px) {
  .image-container {
    height: auto;
  }

  .project-name {
    opacity: 1;
    position: relative;
    height: auto;
    padding: 22px 0 0;
    margin-bottom: 10px;
    background-color: transparent!important;
    order: 2;
  }

  .project-title {
    color: #60676d;
    margin-bottom: 7px;
  }

  .project-subtitle {
    color: #60676d;
  }
}

@media (min-width: 1025px) {
  a:hover .project-name {
    background-color: rgba(40, 42, 043, 0.75);
    opacity: 1;
  }
}
